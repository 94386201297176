<template>
  <b-row>
    <b-col cols="12">
      <b-card>
        <filter-card
          :reset-search="resetSearch"
          :search-data="searchData"
        />
      </b-card>
    </b-col>
    <b-col md="12">
      <b-card no-body>
        <b-card-body class="text-right">
          <b-button
            to="customers/add"
            variant="danger"
          >
            <FeatherIcon icon="PlusIcon" />
            Oluştur
          </b-button>
        </b-card-body>
        <b-card-text>
          <b-table
            responsive="sm"
            :fields="fields"
            :items="dataList"
            striped
          >
            <template #cell(name)="data">
              <div>{{ data.item.name }}</div>
              <div class="text-primary font-small-2">
                {{ data.item.company }}
              </div>
            </template>
            <template #cell(logo_id)="data">
              <FeatherIcon
                :icon="data.item.logo_id !== null? 'CheckCircleIcon' : 'XCircleIcon'"
                :class="data.item.logo_id !== null? 'text-success' : 'text-danger'"
              />
            </template>
            <template #cell(kvkk)="data">
              <FeatherIcon
                :icon="data.item.kvkk === '1'? 'CheckCircleIcon' : 'XCircleIcon'"
                :class="data.item.kvkk === '1'? 'text-success' : 'text-danger'"
              />
            </template>
            <template #cell(username)="data">
              <div>
                {{ data.item.username }}
              </div>
              <div class="text-primary font-small-2">
                {{ data.item.created ? moment(data.item.created).format('DD.MM.YYYY') : null }}
              </div>
            </template>
            <template #cell(phone)="data">
              {{ data.item.phone }}
              <div>
                <b-badge
                  :variant="data.item.kvkk === '1' ? 'light-success' : 'light-danger'"
                >
                  <feather-icon :icon="data.item.kvkk === '1' ? 'CheckCircleIcon':'XCircleIcon'" />
                  KVKK
                </b-badge>
              </div>
            </template>
            <template #cell(control)="data">
              <b-button
                :to="'/customers/view/' + data.item.id"
                variant="outline-primary"
                size="sm"
              >
                Görüntüle
              </b-button>
            </template>
          </b-table>
        </b-card-text>
        <b-card-footer class="d-flex align-items-center justify-content-between">
          <b-pagination
            v-model="currentPage"
            :total-rows="dataCount"
            :per-page="perPage"
            first-number
            last-number
            prev-class="prev-item"
            next-class="next-item"
          >
            <template #prev-text>
              <feather-icon
                icon="ChevronLeftIcon"
                size="18"
              />
            </template>
            <template #next-text>
              <feather-icon
                icon="ChevronRightIcon"
                size="18"
              />
            </template>
          </b-pagination>
          <div class="text-muted font-small-2">
            Toplam {{ dataCount | toNumber }} adet müşteri kartı bulundu.
          </div>
        </b-card-footer>
      </b-card>
    </b-col>
  </b-row>
</template>

<script>
import {
  BCard, BCardText, BCardBody, BRow, BCol, BTable, BCardFooter, BPagination, BButton, BBadge,
} from 'bootstrap-vue'
import FilterCard from '@/views/Customers/components/FilterCard.vue'

export default {
  name: 'Index',
  components: {
    BCard,
    BCardText,
    BCardBody,
    BTable,
    BRow,
    BCol,
    BCardFooter,
    BPagination,
    BButton,
    FilterCard,
    BBadge,
  },
  data() {
    return {
      currentPage: 1,
      perPage: 25,
      search: '',
      fields: [
        {
          key: 'name',
          label: 'Müşteri',
        },
        // {
        //   key: 'logo_id',
        //   label: 'Logo Aktarım',
        //   thClass: 'width-100 text-nowrap text-center',
        //   tdClass: 'width-100 text-nowrap text-center',
        // },
        // {
        //   key: 'kvkk',
        //   label: 'KVKK',
        //   thClass: 'width-100 text-nowrap text-center',
        //   tdClass: 'width-100 text-nowrap text-center',
        // },
        {
          key: 'username',
          label: 'Oluşturma',
          thClass: 'width-100 text-nowrap',
          tdClass: 'width-100 text-nowrap',
        },
        {
          key: 'phone',
          label: 'Telefon',
          thClass: 'width-100 text-nowrap',
          tdClass: 'width-100 text-nowrap',
        },
        {
          key: 'control',
          label: '',
          tdClass: 'width-100 text-nowrap text-right',
        },
      ],
      dataQuery: {
        select: [
          'com_customer.id AS id',
          'com_customer.logo_id AS logo_id',
          'com_customer.name AS name',
          'com_customer.company_name AS company',
          'com_customer.phone AS phone',
          'com_meet.title AS meet',
          'com_customer.kvkk AS kvkk',
          //          'com_customer.logo_id AS logo_id',
          'com_user.name AS username',
          'com_customer.created AS created',
        ],
        limit: 25,
        start: 0,
      },
    }
  },
  computed: {
    dataList() {
      return this.$store.getters['customers/getCustomers']
    },
    dataCount() {
      return this.$store.getters['customers/getCustomersCount']
    },
    filterData() {
      return this.$store.getters['customers/filterData']
    },
  },
  watch: {
    currentPage(val) {
      const page = (val - 1) * 25
      this.pagination(page)
    },
  },
  created() {
    this.getDataList()
    this.getUsers()
  },
  methods: {
    searchData() {
      const where = {}
      if (this.filterData.keyword.length) {
        this.dataQuery.or_like = {
          'com_customer.name': this.filterData.keyword,
          'com_customer.company_name': this.filterData.keyword,
          'com_customer.phone': this.filterData.keyword,
        }
      } else {
        this.dataQuery.or_like = {}
      }
      if (this.filterData.id_com_user) {
        where['com_customer.id_com_user'] = this.filterData.id_com_user
      }
      if (this.filterData.sdate) {
        where['DATE(com_customer.created) >='] = this.filterData.sdate
      }
      if (this.filterData.edate) {
        where['DATE(com_customer.created) <='] = this.filterData.edate
      }
      this.dataQuery.where = where
      this.getDataList()
    },
    resetSearch() {
      this.dataQuery.or_like = {}
      this.search = ''
      this.getDataList()
    },
    pagination(page) {
      this.dataQuery.start = page
      if (this.dataQuery.start === page) {
        this.getDataList()
      }
    },
    getDataList() {
      this.$store.dispatch('customers/customersList', this.dataQuery)
    },
    getUsers() {
      return this.$store.dispatch('users/usersList', {
        select: [
          'com_user.id AS id',
          'com_user.name AS name',
          'com_brand.name AS brand',
          'com_department.title AS department',
          'com_department.title AS location',
        ],
        where: {
          'com_user.status': 1,
        },
      })
    },
  },
}
</script>
